import dayjs from 'dayjs'
import { vMaska } from "maska"
import vClickOutside from 'click-outside-vue3'

export default defineNuxtPlugin(nuxtApp => {
    // log
    nuxtApp.provide('log', () => (comment, value) => console.log(`${comment}:`, value))

    // hex to rgb
    nuxtApp.provide('convertHex', (color, opacity = 0.5) => {
        color = color.replace('#', '')
        const [r, g, b] = [parseInt(color.substring(0, 2), 16), parseInt(color.substring(2, 4), 16), parseInt(color.substring(4, 6), 16)]
        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')'
    })

    // formatNumber
    nuxtApp.provide('formatNumber', (val, context = '', toFixed) => {
        if (val === undefined || isNaN(Number(val))) return '';

        let num = Number(val)
        let numAbs = Math.abs(Number(val))

        if (toFixed !== undefined && typeof toFixed === 'number') {
            return num.toFixed(toFixed);
        }

        if ((numAbs < 1 && context === 'table') || numAbs < 0.1) {
            num = num.toFixed(3)
        } else if (numAbs < 100) {
            num = num.toFixed(2)
        } else if (numAbs < 1000) {
            num = num.toFixed(1)
        } else {
            num = num.toFixed(0)
        }

        return Number(num)
    })

    nuxtApp.provide('formatDate', (dateString) => {
        return dayjs(dateString).format('YYYY-MM-DD')
    })

    nuxtApp.provide('delimitedValue', (value) => {
        if (!value) return value

        let [l, r] = value.toString().split('.')

        return (l.split('')
            .reverse()
            .join('')
            .replace(/\d\d\d/g, '$& ')
            .split('')
            .reverse()
            .join('')) + (r ? `.${r}` : value.toString().includes('.') ? '.' : '' )
    })

    nuxtApp.provide('multipleValue', (value) => {
        if (typeof value !== 'string' && typeof value !== 'number' && !isNaN(value)) return

        const { $formatNumber } = useNuxtApp()
        const str = value.toString().split('.')[0]
        const multiplicity = str.length >= 10 ? 'bln.' : str.length >= 7 ? 'mln.' : str.length >= 4 ? 'th.' : false

        let result = Number(value)

        if (str.length >= 10) {
            result = Number(value) / 1000000000
        } else if (str.length < 10 && str.length >= 7) {
            result = Number(value) / 1000000
        } if (str.length < 7 && str.length >= 4) {
            result = Number(value) / 1000
        }

        return {
            value: $formatNumber(result),
            multiplicity: multiplicity
        }
    })

    nuxtApp.vueApp.directive("input-mask", vMaska)

    nuxtApp.vueApp.directive('click-outside', vClickOutside.directive)
})