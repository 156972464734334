import * as Sentry from "@sentry/browser"

export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig()

    if (config.public.SENTRY_KEY && config.public.SENTRY_ENABLED) {
        Sentry.init({
            dsn: `https://${config.public.SENTRY_KEY}.ingest.us.sentry.io/${config.public.SENTRY_PROJECT_ID}`,
            environment: config.public.SENTRY_ENV || 'Unknown',
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.browserProfilingIntegration(),
                Sentry.replayIntegration()
            ],
            tracesSampleRate: 1.0, 
            tracePropagationTargets: ["localhost", /^\/api/],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            profilesSampleRate: 1.0
        })
    }
})